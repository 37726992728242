(() => {
	let Galerias = {};
	let modal, inner, carousel, header;

	customElements.define('custom-lightbox', class extends HTMLAnchorElement {
		constructor() {
			super();

			const galeria = this.dataset.customLightbox;
			const img   = this.dataset.img;
			const href  = this.getAttribute('href');
			const title = this.getAttribute('title');

			Galerias[galeria] = Galerias[galeria] || {itens: [], length: 0};

			let index = Galerias[galeria].length;

			index = Galerias[galeria].length++;

			Galerias[galeria].itens.push({
				index,
				img,
				href,
				title
			});

			if(!modal) createModal();

			this.addEventListener('click', e => {
				e.preventDefault();

				modal.modal('show');
				render(Galerias[galeria].itens, index);
			});

			createPreloader(img);
		}
	}, {extends: 'a'});

	function createPreloader(img) {
		const $link = document.createElement('link');

		$link.rel = 'preload';
		$link.as  = 'image';
		$link.href = img;

		document.head.appendChild($link);
	}

	function render(galeria, index) {
		inner.innerHTML = galeria.map(item => {
			return `<div class="item${index == item.index ? ' active': ''}">
				<a href="${item.href}" title="${item.title}">
					<img src="${item.img}" alt="${item.title}" loading="lazy">
				</a>
			</div>`
		}).join('\n');

		modal.data('galeria', galeria);
		modal.data('item-ativo', index+1);

		setCarouselHeader();
	}

	function setCarouselHeader() {
		header.style.setProperty('--current', `'${modal.data('item-ativo')}/'`);
		header.style.setProperty('--total', `'${modal.data('galeria').length}'`);
	}

	function createModal() {
		modal = document.createElement('div');
		modal.classList = 'modal modal-lightbox-custom fade';
		modal.innerHTML = `<div class="modal-dialog">
			<div class="modal-content">
				<div>
					<div class="text-right modal-lightbox-header"></div>

					<div class="carousel slide" data-interval="0" id="carousel-lightbox-custom">
						<div class="carousel-inner"></div>
						<a href="#carousel-lightbox-custom" data-slide="prev" class="modal-lightbox-custom-prev" role="button"><span class="sr-only">Prev</span></a>
						<a href="#carousel-lightbox-custom" data-slide="next" class="modal-lightbox-custom-next" role="button"><span class="sr-only">Next</span></a>
					</div>
				</div>
			</div>
		</div>`;

		inner = modal.querySelector('.carousel-inner');
		document.body.appendChild(modal);
		header = modal.querySelector('.modal-lightbox-header');

		modal = $(modal);

		modal.on('hidden.bs.modal', function() {
			inner.innerHTML = '';
		});

		carousel = $('#carousel-lightbox-custom');
		carousel.carousel({
			interval: 0
		});

		carousel.on('slid.bs.carousel', function(e) {
			let active = carousel.find('.item.active').index() + 1;

			modal.data('item-ativo', active);
			setCarouselHeader();
		});
	}
;})();
