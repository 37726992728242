function myOwl(alvo, opcoes){
	if($(alvo).children().length > 1){
	$(alvo).owlCarousel(opcoes);
	}else{
	$(alvo).addClass('owl-loaded');
	}
}

myOwl('#eventos', {
	responsive:{
		0:{
			items: 1
		},
		600:{
			items: 2
		},
		992:{
			items: 3
		}
	},
	nav: true,
	autoplay:true,
  autoplayTimeout:5000,
  autoplayHoverPause:true,
	navText: [
		'',
		''
	],
	loop: true,
});

myOwl('#players', {
	responsive:{
		0:{
			items: 1
		},
		600:{
			items: 2
		},
		992:{
			items: 3
		}
	},
	nav: true,
	autoplay:true,
  autoplayTimeout:5000,
  autoplayHoverPause:true,
	navText: [
		'',
		''
	],
	loop: true,
});

myOwl('#cartaz_interna', {
	responsive:{
		0:{
			items: 1
		},
		600:{
			items: 2
		},
		992:{
			items: 4
		}
	},
	nav: true,
	autoplay:true,
  autoplayTimeout:5000,
  autoplayHoverPause:true,
	navText: [
		'',
		''
	],
	loop: true,
});

myOwl('#cliente__galery', {
	responsive:{
		0:{
			items: 1
		},
		600:{
			items: 2,
			margin: 15
		},
		992:{
			items: 4,
			margin: 15
		}
	},
	nav: true,
	autoplay:true,
	autoplayTimeout:5000,
	autoplayHoverPause:true,
	navText: [
		'<i class="fa fa-chevron-left"></i>',
		'<i class="fa fa-chevron-right"></i>'
	],
	loop: true,
})
