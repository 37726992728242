(() => {
	let Galerias = {};

	customElements.define('image-lightbox', class extends HTMLDivElement {
		constructor() {
			super();

			const galeria = this.dataset.galeria;
			const img     = this.dataset.img;
			const title   = this.dataset.title;

			Galerias[galeria] = Galerias[galeria] || {itens: [], container: null, links: {}};

			if(!Galerias[galeria].container) {
				const $container = document.createElement('div');

				$container.classList.add('img-lightbox-helper');

				Galerias[galeria].container = $container;
				document.body.appendChild($container);
			}

			const hasItem = Galerias[galeria].itens.find(item => item == img);

			if(!hasItem) {
				Galerias[galeria].itens.push(img);

				const $link = document.createElement('a');

				$link.setAttribute('href', img);
				$link.setAttribute('data-lightbox', galeria);
				$link.setAttribute('title', title);

				$link.innerHTML = `<span class="sr-only">Item ${Galerias[galeria].itens.indexOf(img) + 1} da galeria "${galeria}"</span>`

				Galerias[galeria].links[img] = $link;
				Galerias[galeria].container.appendChild($link);
			}

			this.addEventListener('click', e => {
				e.preventDefault();

				Galerias[galeria].links[img].click();
			})

		}
	}, {extends: 'div'})
})();
